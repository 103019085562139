export enum RevisionStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum RevisionTarget {
  SENSORS = "sensors",
  MAPPINGS = "mappings",
  KEYWORDS = "keywords",
}

export enum RevisionOperation {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
}

export type Revision = {
  id: number;
  status: RevisionStatus;
  target: RevisionTarget;
  operation: RevisionOperation;
  data: Record<string, unknown>;
  description: string;
  createdAt: string;
  createdBy: string;
  reviewedAt?: string;
  reviewedBy?: string;
};

export type RevisionsResponse = {
  revisions: Revision[];
  count: number;
};

export type RevisionResponse = {
  revision: Revision;
};
